
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('cotton_report.cotton_production_dashboard') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('warehouse_config.fiscal_year')"
                            label-for="fiscal_year_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalList"
                                id="fiscal_year_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('cotton_config.seasion_name')"
                            label-for="seasons_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.seasons_id"
                                :options="seasonList"
                                id="seasons_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('cotton_config.region')"
                            label-for="region_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.region_id"
                                :options="regionList"
                                id="region_id"
                                :disabled="isOfficeUser"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('cotton_config.zone')"
                            label-for="zone_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.zone_id"
                                :options="zoneList"
                                id="zone_id"
                                :disabled="isOfficeUser"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col  xs="12" sm="12" md="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.district')"
                            label-for="district_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.district_id"
                                :options="districtList"
                                id="district_id"
                                :disabled="isOfficeUser"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col  xs="12" sm="12" md="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.upazila')"
                            label-for="upazilla_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.upazilla_id"
                                :options="upazilaList"
                                id="upazilla_id"
                                :disabled="isOfficeUser"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('cotton_config.unit_name')"
                            label-for="unit_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.unit_id"
                                :options="unitList"
                                id="unit_id"
                                :disabled="isOfficeUser"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col  xs="12" sm="12" md="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('cotton_config.cotton_variety')"
                            label-for="cotton_variety_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.cotton_variety_id"
                                :options="cottonVaritiesList"
                                id="cotton_variety_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col  xs="12" sm="12" md="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('cotton_config.cotton_name')"
                            label-for="cotton_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.cotton_id"
                                :options="cottonNamelist"
                                id="cotton_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
              <b-row>
                <b-col md="12" class="text-right">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" v-if="showData" id="chart">
                                <b-overlay class="border shadow" :show="loading">
                                    <h5 class="text-center">{{ $t('cotton_config.dashboard')}}</h5>
                                    <!-- <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart> -->
                                    <apexchart class="chart-1 text-center px-5" id="vuechart-example" type="bar" height="400" :options="dataOption" :series="series"></apexchart>
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
// import { cottonNameList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { getCottonMarketUserInfo, getUnitAssignedParams } from '@/Utils/data-filtering-by-priviledge'

export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        region_id: 0,
        cotton_variety_id: 0,
        cotton_id: 0,
        zone_id: 0,
        unit_id: 0,
        seasons_id: 0,
        district_id: 0,
        upazilla_id: 0
      },
      showData: false,
      loading: false,
      totalProduction: '',
      totalSell: '',
      remain: '',
      cottonNamelist: [],
      unitList: [],
      zoneList: [],
      districtList: [],
      upazilaList: [],
      rows: [],
    //   series: [
    //       {
    //         name: 'Net Profit',
    //         data: [2000, 1500, 500]
    //       }
    //     ],
    seriesData: [2000, 1500, 500],
    stockStatusSeries: [{
        name: 'Vacant',
        data: []
      }, {
        name: 'Fill',
        data: []
      }],
    stockStatusChartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%',
          width: '10%'
        },
        colors: ['transparent'],
        plotOptions: {
          bar: {
            columnWidth: '35%'
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 0,
              offsetY: 0
            }
          }
        }],
        xaxis: {
          categories: []
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val + '%'
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50
        }
      }
    }
  },
  computed: {
    cottonMarketUserInfo () {
      return getCottonMarketUserInfo()
    },
    currentLocale () {
        return this.$i18n.locale
    },
    // districtList: function () {
    //     return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    // },
    cottonVaritiesList: function () {
        const ObjectData = this.$store.state.agriMarketing.commonObj.cottonVaritiesList.filter(item => item.status === 1)
        return ObjectData.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    seasonList: function () {
        const seasonList = this.$store.state.agriMarketing.commonObj.seasonList.filter(item => item.status === 1)
        return seasonList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    regionList: function () {
        // return this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1)
        return this.cottonMarketUserInfo.regionList.filter(item => item.status === 1)
    },
    series: function () {
      return [{
        name: this.$t('sitePreference.total'),
        data: this.seriesData
      }]
    },
    dataOption () {
      return {
        chart: {
          id: 'vuechart-example',
          type: 'bar'
        },
        plotOptions: {
          bar: {
            barHeight: '50px',
            columnWidth: '30%',
            distributed: true,
            horizontal: false,
            dataLabels: {
              position: 'bottom',
              horizontalAlign: 'center'
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          offsetY: -1,
          style: {
            fontSize: '14px',
            colors: ['#fff']
          }
        },
        labels: [
            this.$t('cotton_config.total_production_kg'),
            this.$t('cotton_config.total_sell_kg'),
            this.$t('cotton_config.remail_kg')
        ],
        colors: ['#33b2df', '#546E7A', '#d4526e']
      }
    },
    isOfficeUser () {
      // return !(this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.is_org_admin)
      return false
    },
    fiscalList () {
      const fiscalList = this.$store.state.commonObj.fiscalYearList
        // const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        // return fiscalyearData.map(item => {
        //     if (this.$i18n.locale === 'bn') {
        //         return { value: item.value, text: item.text_bn }
        //     } else {
        //         return { value: item.value, text: item.text_en }
        //     }
        // })
        return fiscalList.sort((a, b) => parseInt(b.sorting_order) - parseInt(a.sorting_order))
    }
  },
  watch: {
      'search.region_id': function (newVal, oldVal) {
        if (newVal) {
          this.zoneList = this.getZoneList(newVal)
        }
      },
      'search.district_id': function (newVal, oldVal) {
        if (newVal) {
          this.upazilaList = this.getUpazilaList(newVal)
        }
      },
      'search.cotton_variety_id': function (newVal, oldVal) {
        if (newVal) {
          this.cottonNamelist = this.getCottonNamelist(newVal)
        }
      },
      'search.zone_id': function (newVal, oldVal) {
        if (newVal) {
          this.districtList = this.getDistrictList(newVal)
          this.unitList = this.getUnitList(newVal)
        }
      },
      'search.upazilla_id': function (newVal, oldVal) {
        if (newVal) {
          this.unitList = this.getUnitList(newVal)
        }
      },
      currentLocale: function (newVal, oldVal) {
        if (newVal) {
          this.zoneList = this.getZoneList(this.search.region_id)
          this.upazilaList = this.getUpazilaList(this.search.district_id)
          this.cottonNamelist = this.getCottonNamelist(this.search.cotton_variety_id)
          this.unitList = this.getUnitList(this.search.upazilla_id)
        }
      },
      cottonMarketUserInfo: function () {
        this.setUserData()
      }
    },
  created () {
    // this.loadData()
    if (this.isOfficeUser) {
      // const officeTypeObj = this.$store.state.Auth.authUser.office_type_id
      const authOfficeDetail = this.$store.state.Auth.authUser.office_detail
      const unit = this.$store.state.agriMarketing.commonObj.unitList.find(obj => obj.district_id === authOfficeDetail.district_id)
      if (unit !== undefined) {
        this.search.region_id = unit.region_id
        this.search.zone_id = unit.zone_id
        this.search.unit_id = unit.value
        this.search.district_id = unit.district_id
        this.search.upazilla_id = unit.upazilla_id
      }
    }
    this.setUserData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.loading = true
    //   const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
    //   if (response.success) {
    //     this.farmerList = response.data
    //   }
    const unitAssignedParams = getUnitAssignedParams()
    const params = Object.assign({}, this.search, unitAssignedParams)
      const result = await RestApi.getData(agriMarketingServiceBaseUrl, '/cotton/ginner-grower/dashboard/list', params)
      if (result.success) {
        let totalQuantity = 0
        let sellQuantity = 0
        let remainQuantity = 0
        result.data.forEach((element, key) => {
            totalQuantity += element.pro_achi_quantity
            sellQuantity += element.sell_quantity
            remainQuantity += element.remain_quantity
        })
        this.totalProduction = totalQuantity
        this.totalSell = sellQuantity
        this.remain = remainQuantity
        this.series.data = []
        var array = []
        array.push(totalQuantity)
        array.push(sellQuantity)
        array.push(remainQuantity)
        this.seriesData = array
      } else {
        this.totalProduction = 0
        this.totalSell = 0
        this.remain = 0
        this.seriesData = []

        this.$toast.error({
          title: 'Error',
          message: result.message,
          color: '#D6E09B'
        })
      }
      this.loading = false
      this.showData = true
    },
    getCottonNamelist (cvId) {
        const listObject = this.$store.state.agriMarketing.commonObj.cottonNameList.filter(item => item.cotton_variety_id === cvId && item.status === 1)
        return listObject.map((item, index) => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    getZoneList (regionId) {
      //  return this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.region_id === regionId && item.status === 1)
       return this.cottonMarketUserInfo.zoneList.filter(item => item.region_id === regionId && item.status === 1)
    },
    getDistrictList (id) {
      const obj = this.cottonMarketUserInfo.zoneList.find(item => item.value === parseInt(id))
      const districtIdArr = obj.district_ids ? JSON.parse(obj.district_ids) : []
      return this.cottonMarketUserInfo.districtList.filter(item => districtIdArr.includes(parseInt(item.value)))
    },
    getUpazilaList (districtId = null) {
      // const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      const upazilaList = this.cottonMarketUserInfo.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnitList (upazilaId) {
        // const unitList = this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1 && item.zone_id === zoneId)
        const unitList = this.cottonMarketUserInfo.unitList.filter(item => item.status === 1 && item.upazilla_id === upazilaId)
        return unitList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    setUserData () {
      if (this.cottonMarketUserInfo.regionList.length === 1) {
        this.search.region_id = this.cottonMarketUserInfo.regionList[0].value
      }
      if (this.cottonMarketUserInfo.zoneList.length === 1) {
        this.search.zone_id = this.cottonMarketUserInfo.zoneList[0].value
      }
      if (this.cottonMarketUserInfo.districtList.length === 1) {
        this.search.district_id = this.cottonMarketUserInfo.districtList[0].value
      }
      if (this.cottonMarketUserInfo.upazilaList.length === 1) {
        this.search.upazilla_id = this.cottonMarketUserInfo.upazilaList[0].value
      }
      if (this.cottonMarketUserInfo.unitList.length === 1) {
        this.search.unit_id = this.cottonMarketUserInfo.unitList[0].value
      }
    }
  }
}
</script>
